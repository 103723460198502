import { Link } from "react-router-dom";
import useFetch from "../../../Component/Hooks/useFetch";
import Skeleton from "../Loader/Skeleton";
import FirstStats from "./Chart/FirstStats";
import TaxiChart from "./Chart/TaxiChart";

const AdminDashboard = () => {
  const { loading, error, data } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/widgets/taxi`
  );

  const totalWidgets = data?.widgets?.length;
  const totalPrice = totalWidgets * 10;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  // Calculer le trimestre en cours
  const currentQuarter = Math.floor((currentMonth - 1) / 3) + 1;

  // Déterminer les mois de début et de fin du trimestre en cours
  const quarterStartMonth = (currentQuarter - 1) * 3;
  const quarterEndMonth = quarterStartMonth + 2;

  // Créer les dates de début et de fin du trimestre en cours
  const quarterStartDate = new Date(currentYear, quarterStartMonth, 1);
  const quarterEndDate = new Date(currentYear, quarterEndMonth + 1, 0);

  // Filtrer les widgets du trimestre en cours
  const quarterlyWidgets = data?.widgets?.filter((widget) => {
    const widgetDate = new Date(widget.createdAt);
    return widgetDate >= quarterStartDate && widgetDate <= quarterEndDate;
  });
  const quarterlyWidgetsCount = quarterlyWidgets?.length;

  // Calculer le trimestre précédent
  let previousQuarter, previousQuarterYear;
  if (currentQuarter === 1) {
    previousQuarter = 4;
    previousQuarterYear = currentYear - 1;
  } else {
    previousQuarter = currentQuarter - 1;
    previousQuarterYear = currentYear;
  }

  // Déterminer les mois de début et de fin du trimestre précédent
  const prevQuarterStartMonth = (previousQuarter - 1) * 3;
  const prevQuarterEndMonth = prevQuarterStartMonth + 2;

  // Créer les dates de début et de fin du trimestre précédent
  const prevQuarterStartDate = new Date(
    previousQuarterYear,
    prevQuarterStartMonth,
    1
  );
  const prevQuarterEndDate = new Date(
    previousQuarterYear,
    prevQuarterEndMonth + 1,
    0
  );

  // Filtrer les widgets du trimestre précédent
  const previousQuarterWidgets = data?.widgets?.filter((widget) => {
    const widgetDate = new Date(widget.createdAt);
    return (
      widgetDate >= prevQuarterStartDate && widgetDate <= prevQuarterEndDate
    );
  });
  const previousQuarterWidgetsCount = previousQuarterWidgets?.length;

  // Noms des trimestres
  const quarterNames = [
    "Premier trimestre",
    "Deuxième trimestre",
    "Troisième trimestre",
    "Quatrième trimestre",
  ];
  const currentQuarterName = quarterNames[currentQuarter - 1];
  const previousQuarterName = quarterNames[previousQuarter - 1];

  const taxiWidgets = data?.widgets?.filter(
    (widget) => widget.type === "Widget Taxi"
  );
  const taxiList = taxiWidgets?.map((widget) => widget);
  const totalTaxiWidgets = taxiWidgets?.length;

  return (
    <div className="w-full bg-gray-200">
      <div className="flex justify-between items-center mx-5">
        <div className="font-bold tracking-tight text-gray-900 sm:text-5xl md:text-2xl p-6">
          <h1 className="block md:text-4xl">
            Admin
            <span className="text-transparent bg-clip-text bg-gradient-to-tr to-cyan-500 from-blue-600 ml-2">
              Dashboard
            </span>
          </h1>
          <h2>Statistiques d'activation</h2>
        </div>
        <div className="button">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
            <Link to="/createUser">Créer un utilisateur</Link>
          </button>
        </div>
      </div>
      {loading ? (
        <div>
          <Skeleton />
        </div>
      ) : (
        <div className="flex flex-col">
          <div>
            <FirstStats
              totalWidgets={totalWidgets}
              totalPrice={totalPrice}
              quarterlyWidgetsCount={quarterlyWidgetsCount}
              currentQuarterName={currentQuarterName}
              previousQuarterWidgetsCount={previousQuarterWidgetsCount}
              previousQuarterName={previousQuarterName}
            />
          </div>
          <div>
            <TaxiChart
              totalWidgets={totalWidgets}
              totalPrice={totalPrice}
              quarterlyWidgetsCount={quarterlyWidgetsCount}
              totalTaxiWidgets={totalTaxiWidgets}
              taxiList={taxiList}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
